<template>

  <div>
    <!-- Table Container Card -->
    <b-card
      no-body
      class="mb-0"
    >

      <div class="m-2">

        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} - {{ dataMeta.to }} of {{ dataMeta.of }}</span>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <a
                href="javascript:void(0)"
                class="nav-link nav-link-search"
                @click="showSearchBar = !showSearchBar"
              >
                <feather-icon
                  icon="SearchIcon"
                  size="21"
                  class="mr-50"
                />
                <span class="align-middle">Search</span>
              </a>
              <b-input-group
                v-if="showSearchBar"
                class="input-group-merge mr-50"
              >
                <b-form-input
                  v-model="searchQuery"
                  placeholder="Search..."
                />
                <b-input-group-append is-text>
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    @click="searchQuery = ''; showSearchBar = !showSearchBar"
                  />
                </b-input-group-append>
              </b-input-group>

              <b-dropdown
                id="dropdown-form-open-inventory-request"
                ref="filter_dropdown_open_inventory_request"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                class="dropdown-modal"
                right
                variant="link"
              >
                <template #button-content>
                  <feather-icon
                    icon="FilterIcon"
                    size="21"
                    class="align-middle mr-50"
                  />
                  <span class="mr-1">Filters</span>
                </template>
                <inventory-request-filters
                  :status-filter.sync="statusFilter"
                  :service-date.sync="serviceDateFilter"
                  :status-options="statusOptions"
                  :duty-officer.sync="dutyOfficerFilter"
                  :duty-officer-options="dutyOfficerOptions"
                  :service-form.sync="sfFilter"
                  :service-form-options="serviceFormOptions"
                  :store.sync="storeFilter"
                  :store-options="storeOptions"
                  @emitHideFilter="hideFilter"
                  @emitShowFilter="showFilter"
                />
              </b-dropdown>
            </div>
          </b-col>
        </b-row>
        <div v-if="dutyOfficerFilter.length || serviceDateFilter || statusFilter.length || storeFilter.length || sfFilter.length">
          <hr class="dividerHR filter-divider">
          <b-row>

            <!-- Per Page -->
            <b-col
              cols="12"
              md="12"
              class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
            >
              <ul
                class="list-inline d-inline-block mb-1"
              >
                <li
                  v-for="dutyOfiicerVal in dutyOfficerFilter"
                  :key="dutyOfiicerVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromDutyOfficerFilter(dutyOfiicerVal)"
                  >
                    <span class="">{{ resolveDutyOfficerName(dutyOfiicerVal) }}</span>
                  </b-form-tag>
                </li>
                <li
                  v-for="statusVal in statusFilter"
                  :key="statusVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromStatusFilter(statusVal)"
                  >
                    <span class="text-capitalize">{{ statusVal.replace("-", " ") }}</span>
                  </b-form-tag>
                </li>
                <li
                  v-for="storeVal in storeFilter"
                  :key="storeVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromStoreFilter(storeVal)"
                  >
                    <span class="text-capitalize">{{ resolveStoreName(storeVal) }}</span>
                  </b-form-tag>
                </li>
                <li
                  v-for="sfVal in sfFilter"
                  :key="sfVal"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="removeFromSFFilter(sfVal)"
                  >
                    <span class="text-capitalize">{{ resolveSFName(sfVal) }}</span>
                  </b-form-tag>
                </li>
                <li
                  v-if="serviceDateFilter"
                  class="list-inline-item"
                >
                  <b-form-tag
                    class="mb-50"
                    variant="danger"
                    @remove="serviceDateFilter = ''"
                  >
                    {{ serviceDateFilter }}
                  </b-form-tag>
                </li>
                <li
                  class="list-inline-item"
                >
                  <span
                    class="text-danger clear-filter-text mb-50"
                    @click="clearAllFilters"
                  >
                    Clear Filters
                  </span>
                </li>
              </ul>
            </b-col>
          </b-row>
        </div>
      </div>

      <b-table
        ref="refOpenInventoryRequestsTable"
        class="position-relative event_list"
        :class="{ low_items: inventoryRequestData.length < 4 }"
        :items="fetchInventoryRequests"
        responsive
        :fields="tableColumns"
        primary-key="_id"
        :sort-by.sync="sortBy"
        show-empty
        no-sort-reset
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Role -->
        <template #cell(stringID)="data">
          <div class="text-nowrap">
            <b-button
              v-if="canViewThisAction('show', 'InventoryRequest')"
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-black align-middle detail-view-id"
              :to="{ name: 'inventory-inventory-request-view', params: { id: data.item._id } }"
            >
              {{ data.item.stringID }}
              <br>
              <span class="font_normal">fr.</span>
              <b-button
                v-if="canViewThisAction('show', 'ServiceForm')"
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="none"
                class="text-bold-primary align-middle detail-view-id"
                :to="{ name: 'operation-service-forms-show', params: { id: data.item.serviceForm } }"
              >
                {{ data.item.serviceFormStringID }}
              </b-button>
              <b-button
                v-else
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="none"
                class="text-bold-primary align-middle detail-view-id not-button"
              >
                {{ data.item.serviceFormStringID }}
              </b-button>
            </b-button>
            <b-button
              v-else
              v-ripple.400="'rgba(30, 30, 30, 0.15)'"
              variant="none"
              class="text-bold-black align-middle detail-view-id not-button"
            >
              {{ data.item.stringID }}
              <span class="font_normal">fr.</span>
              <b-button
                v-if="canViewThisAction('show', 'ServiceForm')"
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="none"
                class="text-bold-primary align-middle detail-view-id"
                :to="{ name: 'operation-service-forms-show', params: { id: data.item.serviceForm } }"
              >
                {{ data.item.serviceFormStringID }}
              </b-button>
              <b-button
                v-else
                v-ripple.400="'rgba(30, 30, 30, 0.15)'"
                variant="none"
                class="text-bold-primary align-middle detail-view-id not-button"
              >
                {{ data.item.serviceFormStringID }}
              </b-button>
            </b-button>
          </div>
        </template>

        <template #cell(createdBy)="data">
          <div class="text-nowrap">
            <span class="">{{ data.item.createdBy.name }}</span>
          </div>
        </template>

        <template #cell(serviceDate)="data">
          <div class="text-nowrap">
            <span class="">{{ dateFormatWithTime(data.item.serviceDate) }}</span>
          </div>
        </template>

        <template #cell(customerName)="data">
          <div
            class="text-nowrap"
          >
            <span class="">{{ data.item.customerName }}</span>
            <br>
            <span
              v-if="data.item.fileNo"
              class=""
            >
              #{{ data.item.fileNo }}
            </span>
          </div>
        </template>

        <template #cell(stores)="data">
          <div class="">
            <span
              v-for="(option, indx) in data.item.stores"
              :key="indx"
            >
              {{ option.name }}<span v-if="indx + 1 != data.item.stores.length">, </span>
            </span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            :variant="`light-${resolveInventoryRequestStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template
          v-if="canViewThisAction('update-status', 'InventoryRequest') || canViewThisAction('show', 'InventoryRequest') || canViewThisAction('update', 'InventoryRequest')"
          #cell(actions)="data"
        >
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <!-- <b-dropdown-item
              v-if="canViewThisAction('update-status', 'InventoryRequest')"
              @click="toggleStatusModal(data.item._id, data.item.status)"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">Update Status</span>
            </b-dropdown-item> -->

            <b-dropdown-item
              v-if="canViewThisAction('show', 'InventoryRequest')"
              :to="{ name: 'inventory-inventory-request-view', params: { id: data.item._id } }"
            >
              <feather-icon icon="EyeIcon" />
              <span class="align-middle ml-50">View</span>
            </b-dropdown-item>

            <!-- <b-dropdown-item
              v-if="canViewThisAction('update', 'InventoryRequest')"
              :to="{ name: 'inventory-inventory-request-edit', params: { id: data.item._id } }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item> -->
          </b-dropdown>
        </template>

      </b-table>
    </b-card>

    <div
      v-if="totalInventoryRequests > perPage"
      class="mx-2 mb-2"
    >
      <b-row>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="12"
          class="d-flex align-items-center justify-content-center pagination-content"
        >

          <b-pagination
            v-model="currentPage"
            :total-rows="totalInventoryRequests"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            align="center"
            prev-text="Prev"
            next-text="Next"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
              <span>Prev</span>
            </template>
            <template #next-text>
              <span>Next</span>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>

        </b-col>

      </b-row>
    </div>

    <b-modal
      id="update-status-modal-open"
      ref="update-status-modal-open"
      modal-class="status-update-modal"
      cancel-variant="flat-primary"
      ok-variant="success"
      ok-title="Update"
      cancel-title="Cancel"
      centered
      no-close-on-backdrop
      title="Update Status"
      @ok="updateStatus"
    >
      <b-form @submit.prevent="updateStatus">
        <validation-observer
          ref="openInventoryRequestsStatusEditForm"
        >
          <b-form-group
            label="Status*"
            label-for="h-event-status"
          >
            <validation-provider
              #default="{ errors }"
              name="Status"
              vid="status"
              rules="required"
            >
              <v-select
                id="h-event-status"
                v-model="status"
                label="title"
                :options="statusDataOptions"
                :reduce="title => title.code"
                :clearable="false"
                @input="statusValidation == true ? statusValidation = false : null"
              />
              <small class="text-danger">{{ errors[0] }}</small>
              <small
                v-if="statusValidation"
                class="text-danger"
              >
                {{ statusError }}
              </small>
            </validation-provider>
          </b-form-group>
        </validation-observer>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BRow, BCol, BFormInput, BTable, BInputGroup, BInputGroupAppend,
  BBadge, BDropdown, BDropdownItem, BPagination, BForm, BFormGroup, BButton, BFormTag,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { useUtils as useAclUtils } from '@/libs/acl/custom'

import store from '@/store'

import useOpenInventoryRequestList from './useOpenInventoryRequestList'
import inventoryRequestStoreModule from '../inventoryRequestStoreModule'
import InventoryRequestFilters from '../InventoryRequestFilters.vue'

export default {
  components: {
    // UserListAddNew,
    BButton,
    BForm,
    BFormGroup,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BInputGroup,
    BInputGroupAppend,
    BFormTag,

    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    vSelect,

    InventoryRequestFilters,
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    dutyOfficerOptions: {
      type: Array,
      required: true,
    },
    serviceFormOptions: {
      type: Array,
      required: true,
    },
    storeOptions: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      status: 'completed',
      selectedInventoryRequest: '',
      statusValidation: false,
      statusError: 'Valid status is required',
      statusDataOptions: [
        { title: 'Completed', code: 'completed' },
        { title: 'Cancelled', code: 'cancelled' },
        { title: 'Open', code: 'open' },
        { title: 'Collected', code: 'collected' },
        { title: 'Returned', code: 'returned' },
      ],
      showFilterDropdown: process.env.VUE_APP_SHOW_FILTER_DROPDOWN,

      required,
    }
  },

  methods: {
    hideFilter() {
      this.$refs.filter_dropdown_open_inventory_request.hide(true)
    },
    showFilter() {
      this.$refs.filter_dropdown_open_inventory_request.show(true)
    },
    removeFromStatusFilter(item) {
      const index = this.statusFilter.indexOf(item)
      if (index > -1) {
        this.statusFilter.splice(index, 1)
      }
    },

    removeFromDutyOfficerFilter(item) {
      const index = this.dutyOfficerFilter.indexOf(item)
      if (index > -1) {
        this.dutyOfficerFilter.splice(index, 1)
      }
    },

    removeFromStoreFilter(item) {
      const index = this.storeFilter.indexOf(item)
      if (index > -1) {
        this.storeFilter.splice(index, 1)
      }
    },

    removeFromSFFilter(item) {
      const index = this.sfFilter.indexOf(item)
      if (index > -1) {
        this.sfFilter.splice(index, 1)
      }
    },

    clearAllFilters() {
      this.serviceDateFilter = ''
      this.statusFilter = []
      this.dutyOfficerFilter = []
      this.storeFilter = []
      this.sfFilter = []
    },
    resolveDutyOfficerName(id) {
      const user = this.dutyOfficerOptions.find(o => o._id === id)
      if (user) {
        return user.name || ''
      }
      return ''
    },
    resolveStoreName(id) {
      const str = this.storeOptions.find(o => o._id === id)
      if (str) {
        return str.name || ''
      }
      return ''
    },
    resolveSFName(id) {
      const sf = this.serviceFormOptions.find(o => o._id === id)
      if (sf) {
        return sf.stringID || ''
      }
      return ''
    },
    toggleStatusModal(id, status) {
      this.selectedInventoryRequest = id
      this.status = status
      this.$root.$emit('bv::show::modal', 'update-status-modal-open', '')
    },
    updateStatus() {
      this.$refs.openInventoryRequestsStatusEditForm.validate().then(success => {
        if (success) {
          const formData = new FormData()
          formData.append('status', this.status)

          this.$http.patch(`inventory/inventory-request/${this.selectedInventoryRequest}/status/update`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
          })
            .then(() => {
              this.refetchData()
              this.$emit('emitCountInventoryRequest')
              this.$swal({
                title: 'Status Updated!',
                html: 'The status for selected request has been updated',
                // eslint-disable-next-line global-require
                imageUrl: require('@/assets/images/icons/save.png'),
                imageWidth: 80,
                imageHeight: 80,
                imageAlt: 'Custom Icon',
                showCancelButton: false,
                confirmButtonText: 'Okay',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .catch(error => {
              if (error.data.errors) {
                error.data.errors.forEach(validationError => {
                  if (validationError.param === 'status') {
                    this.statusError = validationError.msg
                    this.statusValidation = true
                  }
                })
              } else {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: error.data.message ?? process.env.VUE_APP_DEFAULT_ERROR_MESSAGE,
                    icon: 'AlertTriangleIcon',
                    variant: 'danger',
                  },
                })
              }
            })
        }
      })
    },
  },
  setup() {
    const showSearchBar = ref(false)
    const showFilterOptions = ref(false)
    const INVENTORY_REQUEST_APP_STORE_MODULE_NAME = 'app-open-inventory-requests'

    // Register module
    if (!store.hasModule(INVENTORY_REQUEST_APP_STORE_MODULE_NAME)) store.registerModule(INVENTORY_REQUEST_APP_STORE_MODULE_NAME, inventoryRequestStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVENTORY_REQUEST_APP_STORE_MODULE_NAME)) store.unregisterModule(INVENTORY_REQUEST_APP_STORE_MODULE_NAME)
    })

    const statusOptions = [
      { label: 'Completed', value: 'completed' },
      { label: 'Cancelled', value: 'cancelled' },
      { label: 'Open', value: 'open' },
      { label: 'Collected', value: 'collected' },
      { label: 'Returned', value: 'returned' },
    ]

    const { canViewThisAction } = useAclUtils()

    const {
      fetchInventoryRequests,
      tableColumns,
      perPage,
      currentPage,
      totalInventoryRequests,
      inventoryRequestData,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refOpenInventoryRequestsTable,
      refetchData,

      toDeletedID,
      deleteInventoryRequest,

      // UI
      resolveInventoryRequestStatusVariant,

      // Extra Filters
      statusFilter,
      sfFilter,
      serviceDateFilter,
      dutyOfficerFilter,
      storeFilter,
    } = useOpenInventoryRequestList()

    return {

      // Sidebar

      fetchInventoryRequests,
      tableColumns,
      perPage,
      currentPage,
      totalInventoryRequests,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refOpenInventoryRequestsTable,
      refetchData,
      showSearchBar,
      showFilterOptions,
      toDeletedID,
      deleteInventoryRequest,
      inventoryRequestData,

      // Filter
      avatarText,
      canViewThisAction,

      // UI
      resolveInventoryRequestStatusVariant,

      statusOptions,

      // Extra Filters
      statusFilter,
      sfFilter,
      serviceDateFilter,
      dutyOfficerFilter,
      storeFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
